import React, { useEffect, useState } from "react";
import { apiCall, fileUpload, getFiles } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { HekmaTextSearchField } from '../../../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Materialtable from '../../../../../globalcomponents/materialtable'
import moment from 'moment'
import { Q_status, Q_severity, Q_category, regExpData } from '../../../../common/constants';
import { InputField, AutoOptions, HekmaTextSearchField, AutoOptionsMultiple } from '../../../formElements';
import FormHoc from '../../../hoc/formHoc';
import HekmaDatePicker from '../../../HekmaDatePicker'
import AllFileAttachments from "../../../../../common/fileattachments";
import AuditTrailFunc from '../../../../../common/AuditTrailFunc'

function Queries(propsobj) {
  const [data, setData] = useState([])
  let [siteData, setSiteData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [show, setShow] = useState(false)
  let [options, setOptions] = useState([])
  let [text, setText] = useState("Add Query")
  let [queryId, setQueryId] = useState('')
  let [queryNum, setQueryNum] = useState('')
  let [oldData, setOldData] = useState({});

  const [value, setValue] = React.useState(0);
  let [links, setLinks] = React.useState([]);
  let [docFiles, setDocFiles] = React.useState([])
  let [videoFiles, setVideoFiles] = React.useState([])
  let [docsData, setDocsData] = React.useState([]);
  let [videosData, setVideosData] = React.useState([]);
  let [urlErr, setUrlErr] = React.useState('')
  let [docErr, setDocErr] = React.useState('')
  let [videoErr, setVideoErr] = React.useState('')


  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])


  async function getQueriesData(searchstr) {
    setProgress(true)
    let requestBody = {
      query: `query {
                  searchStudySiteQuery(queryId:"*",queryNum:"${searchstr}",studyId:"${propsobj.formValues.studyId}",siteId:"${propsobj.formValues.siteId}",statuses:"*",assignedTo:"*") {
                    queryId
                    queryNum
                studyId
                studyName
                siteId
                status
                assignedTo
                assignedToName
                queryTitle
                queryDescription
                resolvedDate
                category
                severity
                queryResponse
                sponsorReference1
                sponsorReference2
                siteReference1
                siteReference2
                createdBy
                createdByName
                createdTs
                modifiedBy
                modifiedTs
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('searchStudySiteQuery', requestBody);
    setProgress(false)
    if (!res) {


    } else {
      if (res.data != null && res.data.searchStudySiteQuery.length > 0) {
        setData(res.data.searchStudySiteQuery)
      } else {
        setData([])
      }
    }

  }


  useEffect(() => {
    getQueriesData("*")

  }, []);

  async function getSiteStudyUsers() {
    let requestBody = {
      query: `query {
        getStudySiteContact(studyId:"${propsobj.formValues.studyId}",siteId:"${propsobj.formValues.siteId}") {
          studyId
          siteId
          contactId
          contactName
          role
          primaryContactInd
          email
          phone
          status
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getStudySiteContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getStudySiteContact.length) {
        let data = res.data.getStudySiteContact
        setSiteData(data);
        var optionsUser = data.map((item, i) => { return { label: item.contactId, value: item.contactName } })
        setOptions(optionsUser)
      } else {
        setSiteData([])
      }
    }
  }
  const hocObj = FormHoc(propsobj);
  const textProps = {
    ...hocObj,
    propsobj
  }
  const autoPropstostatus = {
    'options': Q_status,
    default: {
      value: 'Open',
      label: 'Open',
    },
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropstoseverity = {
    'options': Q_severity,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropstocategory = {
    'options': Q_category,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropstousers = {
    'options': options,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['label']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let linksArr = '';
    var linkClearErr = links.length > 0 ? false : true
    links.map(link => {
      if (link.url == "") {
        setUrlErr("Enter URL")
        linkClearErr = false
      }
      else if (regExpData.urlReg.test(link.url)) {
        setUrlErr('')
        linksArr += `{ buArea: "Query",type:"Link", detail1: "${link.url}",detail2:${JSON.stringify(link.description)}}`;
        linkClearErr = true
      } else {
        setUrlErr("Invalid URL")
        linkClearErr = false
      }
    });
    let linkss = linksArr.replace(/}\s*{/g, '},{');
    let docss
    if (docFiles.length == docsData.length) {
      let docsArr = ''
      setDocErr('')
      docsData.map(doc => {
        docsArr += `{buArea: "Query",type:"Document", detail1: "${doc.id}",detail2:"${doc.fileName}",detail3:"${doc.type}"}`;
      })
      docss = docsArr.replace(/}\s*{/g, '},{');
    } else {
      setDocErr("Upload docs to save")
    }
    let videoss
    if (videoFiles.length == videosData.length) {
      let videosArr = ''
      setVideoErr('')
      videosData.map(video => {
        videosArr += `{buArea: "Query",type:"Video", detail1: "${video.id}",detail2:"${video.fileName}",detail3:"${video.type}"}`;
      })
      videoss = videosArr.replace(/}\s*{/g, '},{');
    } else {
      setVideoErr("Upload videos to save")
    }
    var files = []
    // if (linkss.length > 0) {
    files.push(linkss)
    // }
    // if (docss.length > 0) {
    files.push(docss)
    // }
    // if (videoss.length > 0) {
    files.push(videoss)
    // }
    // if (setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length))
    let setGo = await hocObj.handleSubmit('addQuery', event);
    if (text === "Add Query" ? setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length) : setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      if (text === "Add Query") {
        let requestBody = `
        mutation 
        {
          addStudySiteQuery(studyId: "${form.studyId}", siteId: "${form.siteId}" ,queryTitle:"${form.Q_title}",queryDescription:${JSON.stringify(form.Q_Description)},category:"${form.Q_category}",severity:"${form.Q_severity}",assignedTo:"${form.Q_assignedTo.label}",assignedToName:"${form.Q_assignedTo.value}",sponsorReference1:"",sponsorReference2:"",createdByName:"${localStorage.getItem('loggedInUser')}",supportDetails:[${files}]) {
            code
            type
            message
            attributes
          }  
        }
            `;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await apiCall('addStudySiteQuery', requestBody, form);
        setProgress(false);

        if (res && res.data.addStudySiteQuery.code === '200') {
          // propsobj.history.push('/account/studyDetails/site/siteDetails/queries');
          setShow(false)
          propsobj.UpdateFormField({ name: "Q_createddate", value: moment(new Date()).format('YYYY-MM-DD') })
          // propsobj.UpdateFormField({ name: "Q_studyID", value: propsobj.formValues.studyNumber })
          propsobj.UpdateFormField({ name: "Q_status", value: "Open" })
          propsobj.UpdateFormField({ name: "Q_createdby", value: localStorage.getItem("loggedInUser") })
          propsobj.UpdateFormField({ name: "Q_title", value: "" })
          propsobj.UpdateFormField({ name: "Q_Description", value: "" })
          // propsobj.UpdateFormField({ name: "Q_status", value: "" })
          propsobj.UpdateFormField({ name: "Q_severity", value: "" })
          propsobj.UpdateFormField({ name: "Q_category", value: "" })
          propsobj.UpdateFormField({ name: "Q_assignedto", value: "" })
          getQueriesData("*")
        } else {
          console.log('Error!')
        }
      } else {

        let requestBody = `
      mutation 
      {
        updateStudySiteQueryStatus(queryId: "${queryId}", status:"${propsobj.formValues.Q_status}"){
          code
          type
          message
          attributes
        }  
      }
          `;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await apiCall('updateStudySiteQueryStatus', requestBody, form);
        setProgress(false);

        if (res && res.data.updateStudySiteQueryStatus.code === '200') {
          AuditTrailFunc("Study", propsobj.formValues.studyId, "Query Update", "Study ID: " + propsobj.formValues.studyId + " Query ID: " + queryNum, { ...oldData, ...{ Q_status: "Responded" } }, propsobj.formValues)
          setShow(false)
          propsobj.UpdateFormField({ name: "Q_createddate", value: moment(new Date()).format('YYYY-MM-DD') })
          // propsobj.UpdateFormField({ name: "Q_studyID", value: propsobj.formValues.studyNumber })
          propsobj.UpdateFormField({ name: "Q_status", value: "Open" })
          propsobj.UpdateFormField({ name: "Q_createdby", value: localStorage.getItem("loggedInUser") })
          propsobj.UpdateFormField({ name: "Q_title", value: "" })
          propsobj.UpdateFormField({ name: "Q_Description", value: "" })
          // propsobj.UpdateFormField({ name: "Q_status", value: "" })
          propsobj.UpdateFormField({ name: "Q_severity", value: "" })
          propsobj.UpdateFormField({ name: "Q_category", value: "" })
          propsobj.UpdateFormField({ name: "Q_assignedto", value: "" })
          propsobj.UpdateFormField({ name: "Q_responsefromsite", value: "" })
          propsobj.UpdateFormField({ name: "Q_resolvedDate", value: "" })
          setLinks([])
          setDocFiles([])
          setDocsData([])
          setVideoFiles([])
          setVideosData([])
        
          getQueriesData("*")
        } else {
          console.log('Error!')
        }
      }
    }
  }


  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
    // if (rowData.status === "Resolved") {
    //   event.preventDefault()
    // } else {
    //   await propsobj.UpdateFormField({ name: "Q_severity", value: rowData.severity })
    //   await propsobj.UpdateFormField({ name: "Q_category", value: rowData.category })
    //   await propsobj.UpdateFormField({ name: "Q_title", value: rowData.queryTitle })
    //   await propsobj.UpdateFormField({ name: "Q_Description", value: rowData.queryDescription })
    //   await propsobj.UpdateFormField({ name: "Q_createddate", value: moment(new Date(rowData.createdTs)).format('YYYY-MM-DD') })
    //   await propsobj.UpdateFormField({ name: "Q_status", value: rowData.status })
    //   await propsobj.UpdateFormField({ name: "Q_createdby", value: rowData.createdByName })
    //   await propsobj.UpdateFormField({ name: "Q_responsefromsite", value: rowData.queryResponse })
    //   await propsobj.UpdateFormField({ name: "Q_resolvedDate", value: rowData.resolvedDate })
    //   await propsobj.UpdateFormField({
    //     name: "Q_assignedto", value:  rowData.assignedToName
    //   })
    //   setQueryId(rowData.queryId)
    //   setQueryNum(rowData.queryNum)
    //   setText("Update Query")
    //   setShow(true)
    // }

    callGetQueryApi(rowData.queryId)


  }

  const callGetQueryApi = async (id) => {
    let requestBody = {
      query: `query {
      getStudySiteQuery(queryId:"${id}") {
          queryDetails {
    queryId
         queryNum
     studyId
     studyName
     siteId
     status
     assignedTo
     assignedToName
     queryTitle
     queryDescription
     resolvedDate
     category
     severity
     queryResponse
     sponsorReference1
     sponsorReference2
     siteReference1
     siteReference2
     createdBy
     createdByName
     createdTs
     modifiedBy
     modifiedTs
    
     
     }
     supportDetails
     {
         ID
          buArea
          buid
          type
          status
          detail1
          detail2
          detail3
          detail4
          addedById
          addedByName
          addedTs   
     }
      } 
  }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('getStudySiteQuery', requestBody);
    setProgress(false)
    if (!res) {


    } else {
      if (res.data != null && res.data.getStudySiteQuery.queryDetails) {
        let data = res.data.getStudySiteQuery.queryDetails
        let files = res.data.getStudySiteQuery.supportDetails
        setText("Update Query")
        setShow(true)
        let linksdata = files && files.length > 0 ? files.filter(item => item.type === "Link") : []
        var linksArr = []
        if (linksdata.length > 0) {
          for (var i = 0; i < linksdata.length; i++) {
            var obj = {
              id: linksdata[i].ID,
              url: linksdata[i].detail1,
              description: linksdata[i].detail2,
              status: linksdata[i].status
            }
            linksArr.push(obj)
          }
        }
        setLinks(linksArr)
        if (files.length > 0) {
          var urldata = files.filter(item => item.type !== "Link").map(v => ({ ...v, url: "" }))
          for (var i = 0; i < urldata.length; i++) {
            let res = await getFiles('fileupload', urldata[i], "GET");
            if (res.code === 200) {
              let data = JSON.parse(res.attributes)
              urldata[i]["url"] = data.URL
            }

          }
          setDocsData(urldata.length > 0 ? urldata.filter(item => item.type === "Document") : [])
          setVideosData(urldata.length > 0 ? urldata.filter(item => item.type === "Video") : [])
        }

        await propsobj.UpdateFormField({ name: "Q_severity", value: data.severity })
        await propsobj.UpdateFormField({ name: "Q_category", value: data.category })
        await propsobj.UpdateFormField({ name: "Q_title", value: data.queryTitle })
        await propsobj.UpdateFormField({ name: "Q_Description", value: data.queryDescription })
        await propsobj.UpdateFormField({ name: "Q_createddate", value: moment(new Date(data.createdTs)).format('YYYY-MM-DD') })
        await propsobj.UpdateFormField({ name: "Q_status", value: data.status })
        await propsobj.UpdateFormField({ name: "Q_createdby", value: data.createdByName })
        await propsobj.UpdateFormField({ name: "Q_responsefromsite", value: data.queryResponse })
        await propsobj.UpdateFormField({ name: "Q_resolvedDate", value: data.resolvedDate })
        await propsobj.UpdateFormField({
          name: "Q_assignedto", value: data.assignedToName
        })
        setQueryId(data.queryId)
        setQueryNum(data.queryNum)

      }
    }
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      getQueriesData(valSearch)
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    getQueriesData("*")
    setValSearch("")
  }

  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: 'Query ID', field: 'queryNum',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    {
      title: 'Query Title', field: 'queryTitle',
      // render: data => data.firstName + ' ' + data.lastName
    },
    {
      title: 'Query Decription', field: 'queryDescription',
      render: data => data.queryDescription.limit(30)
    },
    { title: "Created Date", field: 'createdTs', render: data => moment.utc(data.createdTs + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('LL') },
    { title: 'Query Status', field: 'status' },
    { title: 'Resolved Date', field: 'resolvedDate', render: data => data.status === "Resolved" ? data.resolvedDate != null ? moment.utc(data.resolvedDate + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('LL') : "" : "" }
  ]


  const handleClick = () => {
    // propsobj.history.push('/account/studyDetails/site/siteDetails/addQuery')
    setShow(true)
    setText('Add Query')
    propsobj.UpdateFormField({ name: "Q_createddate", value: moment(new Date()).format('YYYY-MM-DD') })
    // propsobj.UpdateFormField({ name: "Q_studyID", value: propsobj.formValues.studyNumber })
    propsobj.UpdateFormField({ name: "Q_status", value: "Open" })
    propsobj.UpdateFormField({ name: "Q_createdby", value: localStorage.getItem("loggedInUser") })
    propsobj.UpdateFormField({ name: "Q_title", value: "" })
    propsobj.UpdateFormField({ name: "Q_Description", value: "" })
    // propsobj.UpdateFormField({ name: "Q_status", value: "" })
    propsobj.UpdateFormField({ name: "Q_severity", value: "" })
    propsobj.UpdateFormField({ name: "Q_category", value: "" })
    propsobj.UpdateFormField({ name: "Q_assignedto", value: "" })
    propsobj.UpdateFormField({ name: "Q_responsefromsite", value: "" })
    propsobj.UpdateFormField({ name: "Q_resolvedDate", value: "" })
    getSiteStudyUsers()
  }
  const handleCancel = (event) => {
    // event.preventDefault();
    setShow(false)
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    // propsobj.history.goBack();
  }


  const getUser = (value) => {
    propsobj.UpdateFormField({ name: "Q_assignedTo", value: value.selected })
  }
  //attach event
  const handleLinksChange = (event, index) => {
    var products = links.slice() //or [...links];
    var newUris = products.map((item, i) => {
      if (i == index) {
        item[event.target.name] = event.target.value
      }
      return item
    })
    setLinks(newUris)

  }
  const addNew = () => {
    if (links.length >= 1) {
      if (links[links.length - 1].url == "") {
        setUrlErr("Enter URL")
      }
      else if (!regExpData.urlReg.test(links[links.length - 1].url)) {
        setUrlErr("Invalid URL")
      } else {
        setUrlErr('')
        var newlinks = [...links, { url: "", description: "" }]
        setLinks(newlinks)
      }
    } else {
      var newlinks = [...links, { url: "", description: "" }]
      setLinks(newlinks)
    }
  }
  const removeLink = (e, index) => {
    var arr = [...links]
    // if (index != 0) {
    arr.splice(index, 1);
    // }
    setLinks(arr)
    if (arr.length == 0) {
      setUrlErr('')
    }
  }

  //uploaded docs or videos to save
  const fileSave = async (files) => {
    if (value == 1) {
      var docsInfo = []
      for (var i = 0; i < files.length; i++) {
        let requestBody = { "filename": files[i].name }
        requestBody = JSON.stringify(requestBody);
        setProgress(true)
        let res = await fileUpload('fileupload', requestBody);
        if (res.code == 200) {
          var data = JSON.parse(res.attributes)
          var fdata = {
            id: data.Id,
            fileName: files[i].name,
            type: files[i].type
          }
          docsInfo.push(fdata)
          let blob = new Blob([files[i]]);
          const response = await fetch(data.URL, {
            method: "PUT",
            headers: {
              'Content-Type': files[i].type,
            },
            body: blob,
          });
          const status = await response.status;
          if (status == 200) {
            setProgress(false)
          }

        } else {
          alert('failed to upload')
        }
      }
      setDocsData(docsInfo)
    }
    if (value == 2) {
      var videosInfo = []
      setProgress(true)
      for (var i = 0; i < files.length; i++) {
        let requestBody = { "filename": files[i].name }
        requestBody = JSON.stringify(requestBody);
        setProgress(true)
        let res = await fileUpload('fileupload', requestBody);
        if (res.code == 200) {
          var data = JSON.parse(res.attributes)
          var vdata = {
            id: data.Id,
            fileName: files[i].name,
            type: files[i].type
          }
          videosInfo.push(vdata)
          let blob = new Blob([files[i]]);
          const response = await fetch(data.URL, {
            method: "PUT",
            headers: {
              'Content-Type': files[i].type,
            },
            body: blob,
          });
          const status = await response.status;
          if (status == 200) {
            setProgress(false)
          }
        } else {
          alert('failed to upload')
        }
      }
      setVideosData(videosInfo)
    }

  }

  const onChangeFile = (files) => {
    var filteredArray = [];
    var existingdata = (value == 1) ? docFiles : videoFiles
    if (files.length) {
      for (var index = 0; index < files.length; index++) {
        let exists = false;
        for (var i = 0; i < existingdata.length; i++) {
          if (existingdata[i].name === files[index].name) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          filteredArray.push(files[index]);
        }
      }
    }
    var data = filteredArray.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    if (value == 1) {
      setDocFiles([...docFiles, ...data])
    }
    if (value == 2) {
      setVideoFiles([...videoFiles, ...data])
    }
  }
  const removeFile = (e, name) => {
    if (value == 1) {
      var filesData = [...docFiles]
      docFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
      var newDocsData = [...docsData]
      docsData.map(file => (file.fileName === name && newDocsData.splice(newDocsData.indexOf(file), 1)))
      setDocFiles(filesData)
      setDocsData(newDocsData)
    }
    if (value == 2) {
      var filesData = [...videoFiles]
      videoFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
      setVideoFiles(filesData)
      var newVideosData = [...videosData]
      videosData.map(file => (file.fileName === name && newVideosData.splice(newVideosData.indexOf(file), 1)))
      setVideosData(newVideosData)
    }
  }
  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      {!show &&
        <>
          <div className="d-flex justify-content-end">
            <div className="mr-auto secondary-header">Queries</div>
            <div className="">
              <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                <i className="fa fa-plus-circle mr-1"></i> Add Query</button>
            </div>
          </div>
          <div className="listing-wrap hekmaGrid">
            <div className="card">
              <div className="hekmaGridBox">
                <div className="col-xs-12 col-sm-12 col-md-5">
                  <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                    <HekmaTextSearchField
                      name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                      // label="Search Account"
                      placeholder="Search Query"
                      value={valSearch}
                      handleChange={searchHandle}
                      className="gridSearchField"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                            {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                            <SearchIcon />
                          </InputAdornment>
                        ),

                        endAdornment: (
                          <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                            X
                            {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </div>
                <Materialtable
                  columns={Columns}
                  data={data.length > 0 ? data : []}
                  handleRowClick={handleRowClick}
                />
              </div>
            </div>
          </div>
        </>
      }
      {show &&
        <>
          <div className="d-flex mt-4 justify-content-end">
            <div className="mr-auto secondary-header">{text}</div>
          </div>

          <div className="">
            <form noValidate autoComplete="off" id="addQuery" className="row" onSubmit={formSubmit}>
              <div className="col-md-6">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-6">
                            <InputField {...textProps} name="Q_title" label="Query Title" must={'true'} type="text" disabled={text === "Update Query"} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="Q_createddate" label="Created Date" must={'true'} maxDate={new Date()} disabled={text === "Update Query"} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <InputField {...textProps} multiline={true} rows={3} rowsMax={2} name="Q_Description" label="Description" must={'true'} type="text" disabled={text === "Update Query"} />
                          </div>
                          {/* <div className="col-md-6">
                            <InputField {...textProps} name="Q_studyID" label="StudyID" must={'false'} type="text" disabled />
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <AutoOptions {...autoPropstostatus} name="Q_status" label="Status" keyName='value' must={'true'} disabled={text != "Update Query" || propsobj.formValues.Q_status === "Open" } />
                          </div>
                          <div className="col-md-6">
                            <AutoOptions {...autoPropstoseverity} name="Q_severity" label="Severity" keyName='value' must={'true'} disabled={text === "Update Query"} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <AutoOptions {...autoPropstocategory} name="Q_category" label="Category" keyName='value' must={'true'} disabled={text === "Update Query"} />
                          </div>
                          <div className="col-md-6">
                            <InputField {...textProps} name="Q_createdby" label="Created By" must={'false'} type="text" disabled />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <InputField {...textProps} name="Q_resolvedDate" label="Resolved Date" must={'false'} type="text" disabled />
                          </div>
                          <div className="col-md-6">
                            <AutoOptions {...autoPropstousers} name="Q_assignedto" label="Assigned To" keyName='value' must={'true'} callback={getUser} disabled={text === "Update Query" || propsobj.formValues.Q_status === "Resolved"} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <InputField {...textProps} name="Q_responsefromsite" label="Response from Site" must={'false'} type="text" disabled multiline={true} rows={3} rowsMax={2} />
                          </div>
                          <div className="col-md-6">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="inputBox">
                        {console.log(links, "links")}
                        <AllFileAttachments type="Query" links={links} handleLinksChange={handleLinksChange} removeLink={removeLink} addNew={addNew} fileSave={fileSave} onChangeFile={onChangeFile} docFiles={docFiles} removeFile={removeFile} docsData={docsData} videoFiles={videoFiles} videosData={videosData} urlErr={urlErr} docErr={docErr} videoErr={videoErr} value={value} handleTabsChange={handleTabsChange} disabled={text !== "Add Query"} />
                      </div>
                    </div></div></div>
                <div className="buttonContainer">
                  <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" >Save</button>
                  <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>

                </div>
              </div>
            </form>
          </div>
        </>
      }
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Queries));
