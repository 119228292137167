// import React, { useEffect, useState } from 'react'
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Loader from '../../../common/loader';
// import { appConfig } from '../../../common/constants';
// import Chip from '@material-ui/core/Chip';

// const Training = () => {
//   const [progress, setProgress] = useState(false)
//   const [nctId, setNctId] = useState("")
//   const [nctList, setNctList] = useState("")
//   const [hId, setHId] = useState("")
//   const [hList, setHList] = useState("")

//   useEffect(() => {
//     const requestOptions = {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*',
//       },
//       body: JSON.stringify({ "bucket-name": "trial-match-training-fhir" })
//     };
//     setProgress(true)
//     fetch(appConfig.AIApi + 'get_hospitial_study_list', requestOptions)
//       .then(response => response.json())
//       .then((data) => {
//         setProgress(false)
//         if (data?.details.length > 0) {
//           setHList(data.details)
//         } else {
//           setHList([])
//         }

//         console.log(data, "data")
//       })
//   }, [])
//   const changeHId = (e, value) => {
//     e.preventDefault()
//     setHId(value);
//     setNctId("")
//   }
//   useEffect(() => {
//     if (hId) {
//       const requestOptions1 = {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Origin': '*',
//         },
//         body: JSON.stringify({ "bucket-name": "trial-match", "prefix": `data_mapping_payloads/${hId}/` })
//       };
//       setProgress(true)
//       fetch(appConfig.AIApi + 'get_hospitial_study_list', requestOptions1)
//         .then(response => response.json())
//         .then((data) => {
//           setProgress(false)
//           let newdata = []
//           data.details.map((item) => { newdata.push({ "title": item }) })
//           console.log(newdata, "data1")
//           setNctList(newdata)
//         })
//       // .then((err) => {
//       //   console.log(err)
//       //   setProgress(false)
//       //   alert('Api Error')
//       // })
//     }
//   }, [hId])
//   const onSubmit = (e) => {
//     e.preventDefault();
//   }
//   return (
//     <>
//       <Loader progress={progress} />
//       <form onSubmit={onSubmit}>
//         <div className="row mb-3">
//           <div className="col-md-4">
//             <Autocomplete
//               id="combo-box-demo"
//               value={hId}
//               onChange={(event, newValue) => {
//                 changeHId(event, newValue)
//               }}
//               size='small'
//               options={hList}
//               style={{ width: "100%" }}
//               renderInput={(params) => <TextField {...params} label="Select Hospital" variant="outlined" />}
//             />
//           </div>
//           <div className="col-md-4">
//             {/* <Autocomplete
//               id="size-small-outlined-multi"
//               size="small"
//               value={nctId}
//               onChange={(event, newValue) => {
//                 setNctId(newValue);
//               }}
//               options={nctList.length>0 ? nctList.map((option) => option.title) : []}
//               fullWidth
//               multiple
//               style={{ width: "100%" }}
//               getOptionLabel={(option) => option.title}
//               renderTags={(value, getTagProps) =>
//                 value.map((option, index) => (
//                   <Chip
//                     variant="outlined"
//                     label={option.title}
//                     size="small"
//                     {...getTagProps({ index })}
//                   />
//                 ))
//               }
//               renderInput={(params) => (
//                 <TextField {...params} variant="outlined" label="Select NCT ID" placeholder="Select NCT ID" />
//               )}
//             /> */}
//             <Autocomplete
//               multiple
//               id="size-small-outlined-multi"
//               size="small"
//               options={nctList.length>0 ? nctList.map((option) => option.title):[]}
//               freeSolo
//               fullWidth
//               renderTags={(value, getTagProps) =>
//                 value.map((option, index) => (
//                   <Chip variant="outlined" label={option} {...getTagProps({ index })} />
//                 ))
//               }
//               renderInput={(params) => (
//                 <TextField {...params} variant="outlined" label="Select NCT ID" placeholder="Select NCT ID" />
//               )}
//             />
//           </div>

//           <div className="col-md-4">
//             <div className="buttonContainer" style={{ justifyContent: "center" }}>
//               <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" disabled={!hId && !nctId}>SUBMIT</button>
//               <button className="btn btn-primary-grey btn-rounded btn-small" onClick={() => { setNctId("") }}>CLEAR</button>
//             </div>
//           </div>
//         </div>
//       </form>
//     </>
//   )
// }

// export default Training
// import React, { useEffect, useState } from 'react'
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Loader from '../../../common/loader';
// import { appConfig } from '../../../common/constants';
// import Chip from '@material-ui/core/Chip';

// const Training = () => {
//   const [progress, setProgress] = useState(false)
//   const [nctId, setNctId] = useState("")
//   const [nctList, setNctList] = useState("")
//   const [hId, setHId] = useState("")
//   const [hList, setHList] = useState("")
//   const [modal,setModal] = useState("")
//   const [modalList,setModalList]= useState([])

//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_COMPOSEAPI}Training/get-hospital`,
//         {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           }
//         }).then(response => response.json()).then((res)=>{setHList(res.data)})
//     fetch(`${process.env.REACT_APP_COMPOSEAPI}Prediction/get-models`,
//         {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           }
//         }).then(response => response.json()).then((res)=>{setModalList(res.data)})
//     fetch(`${process.env.REACT_APP_COMPOSEAPI}Prediction/get-ncts`,
//         {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           }
//         }).then(response => response.json()).then((res)=>{setNctList(res.data)})
//   }, [])
//   const changeHId = (e, value) => {
//     e.preventDefault()
//     setHId(value);
//     setNctId("")
//   } 
//   const changeModal = (e, value) => {
//     e.preventDefault()
//     setModal(value);
//   }

//   const onSubmit = (e) => {
//     e.preventDefault();
//     console.log(hId,modal,nctId,"all")
//     let body={
//         "hospital_id": hId,
//         "model_name": modal,
//         "ncts": nctId
//       }
//     fetch(`${process.env.REACT_APP_COMPOSEAPI}Prediction/start-prediction`,
//         {
//           method: 'POST',
//           body: body,
//           headers: {
//             'Content-Type': 'application/json',
//           }
//         }).then(response => response.json()).then((res)=>{console.log(res.data)})
//   }
//   return (
//     <>
//       <Loader progress={progress} />
//       <form onSubmit={onSubmit} style={{background:"white",}}>
//         <div className="row mb-3" style={{padding:"20px",}}>
//           <div className="col-md-3">
//             <Autocomplete
//               id="combo-box-demo"
//               value={hId}
//               onChange={(event, newValue) => {
//                 changeHId(event, newValue)
//               }}
//               size='small'
//               options={hList.length>0 ? hList : []}
//               style={{ width: "100%" }}
//               renderInput={(params) => <TextField {...params} label="Select Hospital" variant="outlined" />}
//             />
//           </div>
//           <div className="col-md-3">
//             <Autocomplete
//               multiple
//               id="size-small-outlined-multi"
//               size="small"
//               options={nctList.length>0 ? nctList : []}
//               freeSolo
//               fullWidth
//               onChange={(event, newValue) => {
//                 setNctId(newValue);
//               }}
//               renderTags={(value, getTagProps) =>
//                 value.map((option, index) => (
//                   <Chip variant="outlined" label={option} {...getTagProps({ index })} />
//                 ))
//               }
//               renderInput={(params) => (
//                 <TextField {...params} variant="outlined" label="Select NCT ID" placeholder="Select NCT ID" />
//               )}
//             />
//           </div>
//           <div className="col-md-3">
//             <Autocomplete
//               id="combo-box-demo"
//               value={modal}
//               onChange={(event, newValue) => {
//                 changeModal(event, newValue)
//               }}
//               size='small'
//               options={modalList.length>0 ? modalList : []}
//               style={{ width: "100%" }}
//               renderInput={(params) => <TextField {...params} label="Select Modal" variant="outlined" />}
//             />
//           </div>
//           <div className="col-md-3">
//             <div className="buttonContainer" style={{ justifyContent: "center" }}>
//               <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" disabled={!hId && !nctId}>SUBMIT</button>
//               <button className="btn btn-primary-grey btn-rounded btn-small" onClick={() => { setNctId("") }}>CLEAR</button>
//             </div>
//           </div>
//         </div>
//       </form>
//     </>
//   )
// }

// export default Training


import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loader from '../../../common/loader';
import { appConfig } from '../../../common/constants';
import Materialtable from '../../../globalcomponents/materialtable';
import Snackbar from '../../../common/snackbar'
import Chip from '@material-ui/core/Chip';
import matchSorter from 'match-sorter';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
const Training = () => {
  const [progress, setProgress] = useState(false)
  const [nctId, setNctId] = useState("")
  const [nctList, setNctList] = useState("")
  const [nctSelected, setNctSelected] = useState([])
  const [hId, setHId] = useState("")
  const [hList, setHList] = useState("")
  const [modal, setModal] = useState("")
  const [modalList, setModalList] = useState([])
  const [modalDir,setModalDir] = useState("")
  const [modalDirList, setModalDirList] = useState([])
  const [inputCond, setInputCond] = useState("")
  const [condOptions, setCondOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [term, setTerm] = useState("")
  const [open, setOpen] = useState(false)
  const [msg, setMsg] = useState("")
  const [variant, setVariant] = React.useState("")
  const [error,setError] = useState(false)
  const callGetCondApi = async (inputValue) => {
    setLoading(true);
    if (inputValue) {
      await fetch(`${process.env.REACT_APP_CLINICALTRAILSURL}api/int/suggest?input=${inputValue}&dictionary=Condition`)
        .then(response => response.json())
        .then(data => setCondOptions(data))
        .catch(error => console.error('Error fetching data:', error));
    }
    setLoading(false);
  }

  const getNCTS = async () => {
    setProgress(true)
    let url
    if (inputCond && !term) {
      url = `${process.env.REACT_APP_CLINICALTRAILSURL}api/int/studies?cond=${inputCond}&from=0&limit=1000`
    }
    if (term && !inputCond) {
      url = `${process.env.REACT_APP_CLINICALTRAILSURL}api/int/studies?term=${term}&from=0&limit=1000`
    }
    if (term && inputCond) {
      url = `${process.env.REACT_APP_CLINICALTRAILSURL}api/int/studies?cond=${inputCond}&term=${term}&from=0&limit=1000`
    }
    console.log(url, "url")
    fetch(url)
      .then(response => response.json())
      .then(data => {
        let dataa = data?.hits?.map((item) => {
          let obj = { ID: item.id, title: item.study.protocolSection.identificationModule.briefTitle, status: item.study.protocolSection.statusModule.overallStatus }
          return obj
        })
        console.log(dataa, "dataa")
        setNctList(dataa)
        setProgress(false)
      })
      .catch(error => { setProgress(false); console.error('Error fetching data:', error) });
  }


  useEffect(() => {
    fetch(`${process.env.REACT_APP_CLINICALTRAILSURL}api/int/suggest?input=&dictionary=Condition`)
      .then(response => response.json())
      .then(data => setCondOptions(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [])


  useEffect(() => {
    setProgress(true)
    fetch(`${process.env.REACT_APP_COMPOSEAPI}Training/get-hospital`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json()).then((res) => { setHList(res.data) })
      .catch(error => console.error('Error fetching data:', error));
    fetch(`${process.env.REACT_APP_COMPOSEAPI}Training/get-model-dir`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json()).then((res) => { setModalDirList(res.data) })
      .catch(error => console.error('Error fetching data:', error));
      setProgress(false)
  }, [])
  const changeHId = (e, value) => {
    e.preventDefault()
    setHId(value);
    setNctId("")
  }
  const changeModal = (e, value) => {
    e.preventDefault()
    setModal(value);
  }
  const changeModalDir = (e, value) => {
    e.preventDefault()
    setModalDir(value);
    setModal("")
    setModalList([])
    setProgress(true)
    fetch(`${process.env.REACT_APP_COMPOSEAPI}Training/get-models-list`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({"dir":value})
      })
      .then(response => response.json()).then((res) => {  setProgress(false);setModalList(res.data) })
      .catch(error => { setProgress(false);console.error('Error fetching data:', error)});
  }

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(hId, modal, nctSelected, "all")
    // setProgress(true)
    setOpen(true)
    setVariant("info")
    setMsg("you will be notified once Training process is completed")
    let body = {
      "hospital_id": hId,
      "model_dir" : modalDir,
      "model_name": modal,
      "ncts": nctSelected
    }
  
    fetch(`${process.env.REACT_APP_COMPOSEAPI}Training/get-models-training`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then(response => response.json()).then((res) => {
        // setOpen(true)
        // // setProgress(false)
        // setVariant("success")
        // setMsg(res.data.msg)
        console.log(res.data)
      }).catch(error => {
        // setProgress(false)
        console.error('Error fetching data:', error)
      });
  }
  
  const Columns = [
    {
      title: 'ID', field: 'ID',
    },
    {
      title: 'Title', field: 'title',
    },
    { title: 'Status', field: 'status' },
  ]

  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
  }
  const rowsSelected = async (rows) => {
    setNctSelected(rows.map((item) => item.ID))
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const clearAll = () =>{
    setNctId("")
    setHId("")
    setModal("")
    setModalDir("")
    setInputCond("")
    setTerm("")
    setNctList("")
  }
  return (
    <>
      <Loader progress={progress} />
      <Snackbar open={open} variant={variant} message={msg} handleClose={handleClose} />
      <form onSubmit={onSubmit} style={{ background: "white", }}>
        <div className="row mb-3" style={{ padding: "20px", }}>
          <div className="col-md-4">
            <Autocomplete
              id="combo-box-demo"
              value={hId}
              onChange={(event, newValue) => {
                changeHId(event, newValue)
              }}
              size='small'
              options={hList.length > 0 ? hList : []}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField  error={error}
              helperText={error ? "This field is required" :""} {...params} label="Select Hospital" variant="outlined" InputLabelProps={{
                className:'must',
                // shrink: props.shrink || false
            }}/>}
            />
          </div>
          <div className="col-md-4">
            <Autocomplete
              id="combo-box-demo"
              value={modalDir}
              onChange={(event, newValue) => {
                changeModalDir(event, newValue)
              }}
              size='small'
              options={modalDirList.length > 0 ? modalDirList : []}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} error={error}
              helperText={error ? "This field is required" :""} label="Select Modal Directory" variant="outlined" />}
            />
          </div>
          <div className="col-md-4">
            <Autocomplete
              id="combo-box-demo"
              value={modal}
              onChange={(event, newValue) => {
                changeModal(event, newValue)
              }}
              size='small'
              options={modalList.length > 0 ? modalList : []}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} error={error}
              helperText={error ? "This field is required" :""} label="Select Modal" variant="outlined" />}
            />
          </div>
          <div className="col-md-12 mt-3 mb-3">
            <div className='row'>
              <div className='col-md-4'>
                <Autocomplete
                  id="combo-box-demo"
                  value={inputCond}
                  onChange={(event, newValue) => {
                    // console.log(newValue.inputValue, "innn")
                    setInputCond(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    callGetCondApi(newValue)
                  }}
                  size='small'
                  // filterOptions={filterOptions}
                  options={condOptions.length > 0 ? condOptions : []}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condition/disease"
                      placeholder='Eg: Diabetes'
                      variant="outlined"
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div className='col-md-4'>
                <TextField
                  // error={error}
                  id="outlined-error-helper-text"
                  label="Other terms"
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  fullWidth
                  size='small'
                  // helperText={error ? "Required" : ""}
                  variant="outlined"
                />
              </div>
              <div className='col-md-4'>
                {/* // <Autocomplete
                        //     multiple
                        //     id="size-small-outlined-multi"
                        //     size="small"
                        //     options={nctList.length > 0 ? nctList : []}
                        //     freeSolo
                        //     fullWidth
                        //     onChange={(event, newValue) => {
                        //         setNctId(newValue);
                        //     }}
                        //     renderTags={(value, getTagProps) =>
                        //         value.map((option, index) => (
                        //             <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        //         ))
                        //     }
                        //     renderInput={(params) => (
                        //         <TextField {...params} variant="outlined" label="Select NCT ID" placeholder="Select NCT ID" />
                        //     )}
                        // /> */}
                <div style={{ marginTop: "13px" }}>
                  {/* <ArrowForwardIcon/> */}
                  <button type="button" className="btn btn-primary-blue btn-rounded btn-small" onClick={() => getNCTS()}>GET NCT(s)</button>
                </div>
              </div>
              {nctList.length > 0 && <div className='col-md-12 mt-3'>
                <div className="listing-wrap hekmaGrid">

                  {/* <div className="d-flex  justify-content-end">
                                            <div class="secondary-header mr-auto">Select NCT(S)</div>
                                        </div> */}
                  <div className="hekmaGridBox">

                    <Materialtable
                      columns={Columns}
                      data={nctList}
                      title="Select NCT(s)"
                      handleRowClick={handleRowClick}
                      rowsSelected={rowsSelected}
                      selection={true}
                      search={true}
                    />
                  </div>


                </div>
              </div>}
            </div>
          </div>

          <div className="col-md-12">
            <div className="buttonContainer" style={{ justifyContent: "right" }}>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" disabled={!hId && !nctId}>SUBMIT</button>
              <button type="button" className="btn btn-primary-grey btn-rounded btn-small" onClick={clearAll}>CLEAR</button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Training

