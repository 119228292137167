// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../../common/apiconfig';
import NCTIDs from '../../../../globalcomponents/nctidDropdowns'
import Loader from '../../../../common/loader';
const NCTPage = (propsobj) => {
  const [data, setData] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [nctid, setNctId] = React.useState({ label: "", value: "", info: "" })
  const [loading, setLoading] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [thresholdValue, setThresholdValue] = React.useState({})
  const [nctvalue, setNctvalue] = React.useState(null)

  React.useEffect(() => {

    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);
  const autoHandleChange = async (event, newValue) => {
    event.preventDefault();
    setNctId(newValue.value)
    setNctvalue(null)
  }
  const handleThreshold = async (obj) => {
    setThresholdValue(obj.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    propsobj.UpdateFormField({ name: "pirNct", value: nctvalue })
    propsobj.UpdateFormField({ name: "thresholdValue", value: thresholdValue })
    propsobj.history.push('/site/newpir/searchcriteria')
  }

  const onchange = (e, value) => {
    e.preventDefault()
    setNctvalue(value)
  }
  const openPir = () => {
    propsobj.UpdateFormField({ name: "pirNct", value: "" })
    propsobj.UpdateFormField({ name: "newInclusion", value:[] })
    propsobj.UpdateFormField({ name: "newExclusion", value: [] })
    propsobj.UpdateFormField({ name: "thresholdValue", value: "" })
    propsobj.UpdateFormField({ name: "patientids", value: "" })
    propsobj.history.push('/site/newpir/iesearch')
  }
  return (
    <>
      <Loader progress={loader} />
      <NCTIDs mainHeader="Ask Yekka" subHeader="Quickly Find Patients to Cure" onSubmit={onSubmit} options={options} value={nctid} placeholder="Enter clinical Trial ID (Eg: NCT00000XXX)" autoHandleChange={autoHandleChange} onchange={onchange} nctvalue={nctvalue} loading={loading} threshold={true} handleThreshold={(obj) => handleThreshold(obj)} showtextfield={true} openPir={openPir} subtext="Search by I/E Criteria"/>

    </>
  );
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NCTPage));