import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
    float:"right",
    paddingBottom:"20px"
  },
}));

export default function PaginationRounded({onPageChange,count}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Pagination count={count} shape="rounded" onChange={onPageChange}/>
      {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
    </div>
  );
}
