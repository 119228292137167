import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import MaterialTable from 'material-table';
import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../globalcomponents/formElements';

function PatientDetails(props) {
  //console.log(props.formValues, "patientdata")
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [firstName, setFirstName] = useState(props.formValues.patientdata.firstname);
  let [lastName, setLastName] = useState(props.formValues.patientdata.lastName)
  let [familyName, setFamilyName] = useState(props.formValues.patientdata.familyName || "")
  let [city, setCity] = useState(props.formValues.patientdata.city)
  let [state, setState] = useState(props.formValues.patientdata.state)
  let [country, setCountry] = useState(props.formValues.patientdata.country)
  let [zip, setZip] = useState(props.formValues.patientdata.zip)
  let [address1,setAddress1]=useState(props.formValues.patientdata.address1)
  let [gender, setGender] = useState(props.formValues.patientdata.gender)
  let [birthdate, setDob] = useState(props.formValues.patientdata.birthdate || "")
  let [language, setLanguage] = useState(props.formValues.patientdata.language)
  let [phone, setPhone] = useState(props.formValues.patientdata.phone)
  useEffect(() => {
    async function getApiData() {
      let url
      setProgress(true)
      if (props.formValues.patientdata?.patient360 === "Y") {
        url = `${appConfig.BaseUrl}patient?ids=${props.formValues.patientdata?.ehrPatientId}`
      } else if (props.formValues.patientdata?.patientId != "" || null || undefined) {
        url = `${appConfig.BaseUrl}patient?hekmaMail=${props.formValues.patientdata.email}`
      } else {
        url = `${appConfig.BaseUrl}patient?ids=${props.formValues.patientdata.subjectId}`
      }
      await fetch(url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(responseData => {
          setProgress(false)
          if (responseData && responseData.data.length > 0) {
            var data = responseData.data[0]
            let firstName = data.name[0].given[0]
            let lastName = data.name[0]?.given[1] || ""
            let familyName = data.name[0].family[0]
            let city = data.address[0].city
            let state = data.address[0].state
            let country = data.address[0].country
            let zip = data.address[0].postalCode || ""
            let address=data.address[0].line[0]
            let gender = data.gender
            let dob = data.birthDate
            // let language = data.communication[0].language.text
            let language = data.communication?.[0]?.language?.coding?.[0]?.display || '';

            let phone = data.telecom[0].value
            setFirstName(firstName)
            setLastName(lastName)
            setFamilyName(familyName)
            setCity(city)
            setState(state)
            setCountry(country)
            setZip(zip)
            setGender(gender)
            setDob(dob)
            setLanguage(language)
            setPhone(phone)
            setAddress1(address)
          }
        })
    }
    getApiData();
  }, []);

  const { email = "",address2 = "" } = props.formValues.patientdata
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <>


        {/* <div className="d-flex ">
          <div class="secondary-header mr-auto">Patient Details</div> 
          <div className="">
          </div>
        </div> */}

        <div className="mt2rem">
          <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">PERSONAL DETAILS</div>
                        <div className="inputBox">
                          <ViewRow textvalue={firstName+' '+lastName} text="Name" />
                          <ViewRow textvalue={familyName} text="Family Name" />
                          <ViewRow textvalue={phone} text="Phone" />
                          <ViewRow textvalue={email} text="Email" />
                          {/* <ViewRow text="Primary Contact" /> */}
                          <ViewRow text="Study ID" />
                          <ViewRow text="Status" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">ADDRESS INFORMATION</div>
                        <div className="inputBox">
                          <ViewRow textvalue={address1} text="Address Line 1" />
                          <ViewRow textvalue={address2} text="Address Line 2" />


                          <ViewRow textvalue={zip} text="Zipcode" />
                          <ViewRow textvalue={country} text="Country" />

                          <ViewRow textvalue={city} text="City" />
                          <ViewRow textvalue={state} text="State" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">CARE GIVER DETAILS</div>
                        <div className="inputBox">
                          <ViewRow text="Relationship" />
                          <ViewRow text="Name" />
                          <ViewRow text="Phone" />
                          <ViewRow text="Address" />
                          <ViewRow text="Gender" />
                          <ViewRow text="Period" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">DEMOGRAPHICS</div>
                        <div className="inputBox">
                          <ViewRow textvalue={gender} text="Gender" />
                          <ViewRow text="Height" />


                          <ViewRow text="Weight" />
                          <ViewRow textvalue={birthdate} text="Date of birth" />
                          <ViewRow text="Ethnicity" />
                          <ViewRow textvalue={language} text="Language" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="panel-primary-wrap">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-page-header">PRIMARY CONTACT DETAILS</div>
                        <div className="inputBox">
                          <ViewRow text="Relationship" />
                          <ViewRow text="Name" />
                          <ViewRow text="Phone" />
                          <ViewRow text="Address" />
                          <ViewRow text="Gender" />
                          <ViewRow text="Period" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientDetails));


