import SearchCriteria from '../../../globalcomponents/yekka/IEPage'
import {  useSelector } from 'react-redux';
export const SearchCriteriaPIR =()=>{
    let reduxstateobj = useSelector(state => state);
    console.log(reduxstateobj,"redux")
    return(
        // <SearchCriteria Inclusion={reduxstateobj.form.newInclusion} Exclusion={reduxstateobj.form.newExclusion} sponsor={true} nextPageUrl={"/account/newpir/patients"} nctid={reduxstateobj.form.pirNct} patientids={reduxstateobj.form.patientids} thresholdValue={reduxstateobj.form.thresholdValue} app="app"/>
        <SearchCriteria
        //  Inclusion={reduxstateobj.form.newInclusion} 
        //  Exclusion={reduxstateobj.form.newExclusion} 
         sponsor={true} nextPageUrl={"/account/newpir/patients"} 
        nctid={reduxstateobj.form.pirNct} 
        //  patientids={reduxstateobj.form.patientids}
        thresholdValue={reduxstateobj.form.thresholdValue} app="app" 
        // pagination={reduxstateobj.form.yekkapagination}
        />
    )
}