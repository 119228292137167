import React,{useEffect,useState} from 'react';
//import UserProfile from '../../../img/user-profile.png';
import { logOut } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';

function UserMenu(props) {
  const[user,setUser]=useState('')
 const checkedUser = user === 'null' ? null : user;
   useEffect(() => {
    // let responseObj = JSON.parse(localStorage.getItem('roles'));
    // console.log(responseObj)
    // setRole(responseObj.role)
    let user=localStorage.getItem("loggedInUser")
    setUser(user)
  }, [localStorage.getItem("loggedInUser")])
  const handleChange = (event) => {
    props.history.push('/patient/profile')
  }
  const handleClick = (event) => {
    logOut();
  };
  return (
    <>
      <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {/* <img className="img-profile rounded-circle mr-md-2 mr-xs-0" src={UserProfile} alt="user-profile" /> */}
       <span className=" d-none d-lg-inline user-text">{checkedUser ? `Hello, ${checkedUser}` : 'Hello'}</span>
      </a>

      <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
      <a className="dropdown-item" onClick={handleChange}>
          <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Profile
        </a>
        <a className="dropdown-item" onClick={handleClick}>
          <i className="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
        
      </div>
    </>
  );
}
export default withRouter(UserMenu)